var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-dropdown',{staticClass:"v-actions",attrs:{"size":"sm","variant":"light","toggle-class":"text-decoration-none","dropleft":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-three-dots-vertical')]},proxy:true}])},[_c('div',_vm._l((_vm.actions.filter(
          (e) => e.showInState == _vm.currentState || e.showInState === 'all'
        )),function(action){return _c('b-dropdown-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(action.label),expression:"action.label",modifiers:{"hover":true,"left":true}}],key:action.key,on:{"click":function($event){return _vm.goAction(
            _vm.reference,
            action.key,
            action.to,
            action.title,
            action.text,
            _vm.row,
            action.params
          )}}},[_c('b-icon',{attrs:{"icon":action.icon}})],1)}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }